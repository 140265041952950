import { RectangleStackIcon } from "@heroicons/vue/20/solid";

export const theRoleOfThePropertyDiagram = {
  order: 2,
  name: "The role of the property diagram",
  keywords: "stacking plan",
  subcategory: "Properties",
  icon: RectangleStackIcon,
  iconColor: "text-orange-400",
  markdown: `# The role of the property diagram

  {% inlineRouterLink articleId="what-is-a-property?" %}Properties{% /inlineRouterLink %} are a fundamental concept in Tower Hunt, often organizing hundreds of related {% inlineRouterLink %}datapoints{% /inlineRouterLink %} so you can see what's really happening. The property diagram is an interactive 2D visualization of one or more properties. The diagram is designed to help you quickly discover **and** create datapoints about the property. Read on to learn more about all of the ways the diagram helps you get more done.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/propertyDiagram0.png)

  {% callout type="tip" %}
  **Tip:** Learn more about the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" %}visual features{% /inlineRouterLink %} and {% inlineRouterLink articleId="property-diagram-interactions" %}interactions{% /inlineRouterLink %} of the diagram to maximize your productivity.
  {% /callout %}

  ## Property diagram vs stacking plan

  At first glance, a Tower Hunt property diagram looks like a stacking plan. Look deeper, though, and you'll quickly realize that the diagram does more. Here are a few key differences:

  - **Property rights**: The diagram illustrates how the property is controlled across various rights. This means you can figure out which companies and contacts are responsible for various spaces. Property rights also link to investments and loans, helping you draw connections between income, value, and capitalization at a property.
  - **Data relationships**: The various parts of the diagram connect to related datapoints, and it is easy to navigate among them without leaving the diagram. This allows the diagram to act like a visual filing cabinet for all aspects of the property.
  - **Public and private**: Tower Hunt combines public and private information. This means that, whether you build a property diagram from scratch or open a diagram others have contributed to, you can easily add Safezone datapoints that reflect your unique view of the market.
  - **Multi-building**: Tower Hunt properties are designed to support multiple buildings, as well as more complex physical structures that look like single buildings but have distinct parts. No outside help is needed to leverage this additional context.
  - **Multi-property**: The property diagram lets you view multiple properties side-by-side, with each property automatically scaled for height and acreage (additional datapoints required). Using the expandable map, this makes it easy to compare assets within a submarket or even across markets.
  - **Integrated map**: The diagram has a built-in {% inlineRouterLink %}Mini Map{% /inlineRouterLink %} that shows you the location of the property, its land coverings, and its fee parcels (additional datapoints required). The map markers visually connect and allow navigation to the corresponding part of the diagram.
  - **Time travel**: The timeline lets you view the property at different moments in time, making it easy to see the history of the property and the effect of proposed development.

  ## Benefits for users

  Understanding what's really happening at a property comes down to context. If you understand the nuances of a property and the links it has to other aspects of the market, then you have sufficient context to take action. Here are some ways the property diagram delivers context:

  - **Visual**: Since real estate is a physical asset, you will understand it better if you can see the relative size and location of different parts of a property. The diagram not only shows this, but it connects to photos and the {% inlineRouterLink %}Mini Map{% /inlineRouterLink %}.
  - **Interactive**: Each part of the diagram can be selected, making it easy to navigate to related datapoints and create new datapoints. Additionally, the {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %} and {% inlineRouterLink %}Mini Map{% /inlineRouterLink %} are interactive, letting you work with time and location on the same page.
  - **Flexible**: The diagram lets you view multiple properties side-by-side on the same page. When used with the {% inlineRouterLink %}Compact Map{% /inlineRouterLink %} and resizeable {% inlineRouterLink articleId="sidebar" sectionName="Resize sidebar" %}sidebar{% /inlineRouterLink %}, you can easily switch properties, markets, and building heights with ease.
  - **Connected**: Various parts of the diagram &mdash; especially {% inlineRouterLink %}property rights{% /inlineRouterLink %} &mdash; connect outward to the broader market through {% inlineRouterLink %}investments{% /inlineRouterLink %} and, ultimately, {% inlineRouterLink %}players{% /inlineRouterLink %}. The {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %} lets you navigate directly to relevant sections of the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %} without leaving the page.
  - **Built on datapoints**: {% inlineRouterLink %}Datapoints{% /inlineRouterLink %} help you in numerous ways, from {% inlineRouterLink %}pay-as-you-go pricing{% /inlineRouterLink %}, {% inlineRouterLink %}data quality metrics{% /inlineRouterLink %}, and {% inlineRouterLink %}selective unlocking{% /inlineRouterLink %} to productivity tools such as {% inlineRouterLink %}note-taking{% /inlineRouterLink %} and {% inlineRouterLink %}tasks{% /inlineRouterLink %}. Datapoints also allow you to add your own {% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} data on top of publicly available diagrams, saving you time and effort.

  ## Benefits for contributors

  There are a variety of {% inlineRouterLink %}reasons that you might help build a property diagram{% /inlineRouterLink %}. In every case, the tools strike a helpful balance between accessibility and complexity. This means that all you need is an understanding of the real estate and these help articles. Here are some ways the property diagram helps you contribute:

  - **Accessible**: Anyone can contribute to a property diagram. You don't need any equipment or specialized skills beyond your knowledge of real estate and these help articles. Use the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %} to build the diagram in real-time. Use the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %} to add details to specific parts of the diagram.
  - **Rewarding**: Time and effort that you invest in a property diagram is paid back in {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}usage credits{% /inlineRouterLink %} and {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" sectionName="Revenue types" %}money{% /inlineRouterLink %} when other users access your contributed datapoints. Beyond the economic incentive they provide, property diagrams also evolve to contain day-to-day {% inlineRouterLink %}notes{% /inlineRouterLink %}, {% inlineRouterLink %}tasks{% /inlineRouterLink %}, {% inlineRouterLink %}files{% /inlineRouterLink %}, and other {% inlineRouterLink %}Safezone datapoints{% /inlineRouterLink %} that make you more productive.
  - **Flexible**: The diagram is designed to start simple. Parcels, buildings, investments, players, and much more can be added with minimal upfront effort. You &mdash; or other users in your market &mdash; can add details incrementally. This means you can set up just the data that is important to you and then keep moving forward.
  - **Safe**: Tower Hunt combines public and private information. Every action that inserts data into the database presents a {% inlineRouterLink %}color-coded privacy confirmation{% /inlineRouterLink %} so that you know who will be able to see your contributed datapoints.
  - **Well-documented**: Tower Hunt includes a robust {% inlineRouterLink %}Help Center{% /inlineRouterLink %} with dozens of articles that explain every feature of the product. You can easily invoke relevant help using the {% inlineAppIcon iconName="questionMark" %}**question mark icon**{% /inlineAppIcon %} you see next to specific features. Help articles will appear in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}, and you can {% inlineRouterLink %}open them in a new tab{% /inlineRouterLink %}. We're here to help you succeed!`,
};
